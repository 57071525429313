import React from 'react'

import Layout from '../components/Layout'
import SocialBar from '../components/SocialBar';

import './index.scss'

import mugshot from '../images/mugshot.jpg'


// =============================================================================
const IndexPage = () => (
  <Layout>
    <SocialBar />
    <section className="LandingPane">
      <div className="sidebar"></div>
      <div className="mugshot">
        <img src={mugshot} alt="Me smiling." />
      </div>
      <div className="content">
        <h1 className="title">Hi! I'm Kier.</h1>
        <p className="subtitle">
          I create systems and solve problems and stuff.
        </p>
        <hr />
        <p>
          I'm a software architect for maritime robotics systems now.  I used to
          write software and design hardware for massively-parallel and
          neuromorphic computers.
        </p>
        <p>
          I'm rebuilding this site from scratch at the moment.  Hopefully I'll
          be finished soon.
        </p>
        <p>
          In the meantime,
          {' '}
          <a href="https://kiers.blog/">
           I have a blog that's totally worth reading!
          </a>
        </p>
      </div>
    </section>
  </Layout>
)


// -----------------------------------------------------------------------------
export default IndexPage
