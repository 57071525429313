import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './SocialBar.scss'


// =============================================================================
const SOCIAL_LINKS = [
  {
    url: 'https://github.com/DuFace',
    title: 'DuFace',
    type: 'github',
  },
  {
    url: 'http://uk.linkedin.com/in/kierdugan',
    title: 'Kier Dugan',
    type: 'linkedin',
  },
  {
    url: 'https://twitter.com/KierDugan',
    title: '@KierDugan',
    type: 'twitter',
  },
  {
    url: 'http://stackexchange.com/users/151675/duface',
    title: 'DuFace',
    type: 'stack-exchange',
  },
]


// =============================================================================
const SocialBar = () => (
  <nav className="Social">
    <ul>
      {SOCIAL_LINKS.map(({ url, title, type }) => (
        <a href={url} title={title} className={type}>
          <FontAwesomeIcon icon={['fab', type]} size="2x" />
        </a>
      ))}
    </ul>
  </nav>
)

// -----------------------------------------------------------------------------
export default SocialBar
